<template>
  <div class="allTemplate">
    <div class="searchflex">
      <div class="searchLeft">
        <div class="searchInput">
          <el-select
            size="mini"
            v-model="products"
            @change="getSearch()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in productsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="searchInput">
          <el-input
            size="mini"
            v-model="search"
            @input="getSearch()"
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
          ></el-input>
        </div>
      </div>
      <div class="searchRight">
        <img src="@/assets/image/add_new.png" class="addIcon" @click="add()" />
      </div>
    </div>
    <yh-table
      tableBorder
      :tableHeight="tableHeight"
      :tableLoading="tableLoading"
      :tableHead="tableHead"
      :tableData="tableData"
    >
      <template #tableRow="{ row, item }">
        <span>{{ row[item.prop] }}</span>
      </template>
      <el-table-column label="操作" width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <yh-popover
            popoverTitle="修改"
            @savePopUp="edit(scope.row)"
          ></yh-popover>
          <yh-popover
            popoverOnly="1"
            popoverTitle="删除"
            @savePopUp="del(scope.row)"
          >
            确定删除该信息吗？
          </yh-popover>
        </template>
      </el-table-column>
    </yh-table>

    <!-- 新增编辑 -->
    <yh-dialog :dialogObj="dialogObj">
      <yh-form
        ref="formName"
        labelWidth="110px"
        :formRule="formRule"
        :formHead="formHead"
        :formData="formData"
      ></yh-form>
      <div class="tab">
        <img
          src="@/assets/image/add_new.png"
          class="addIcons"
          @click="addList()"
        />
        <el-tabs
          size="mini"
          v-model="activeName"
          type="card"
          @tab-click="handleClick"
        >
          <el-tab-pane label="档案字段" name="first">
            <el-table
              border
              size="mini"
              :data="formData.fieldMetas"
              style="width: 100%"
              height="350px"
            >
              <el-table-column prop="fieldCode" label="编码" width="97">
              </el-table-column>
              <el-table-column prop="fieldName" label="名称" width="96">
              </el-table-column>
              <el-table-column prop="fieldType" label="类型" width="120">
                <template slot-scope="scope">
                  {{ scope.row.fieldType | typeFilter }}
                </template>
              </el-table-column>
              <el-table-column prop="fieldSettings" label="字段配置">
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <yh-popover
                    popoverTitle="修改"
                    @savePopUp="editList(scope.row, scope.$index)"
                  ></yh-popover>
                  <yh-popover
                    popoverOnly="1"
                    popoverTitle="删除"
                    @savePopUp="delList(scope.$index)"
                  >
                    确定删除该信息吗？
                  </yh-popover>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="查询字段" name="second">
            <el-table
              border
              size="mini"
              :data="formData.queryFields"
              style="width: 100%"
              height="350px"
            >
              <el-table-column prop="queryCode" label="编码" width="97">
              </el-table-column>
              <el-table-column prop="fieldCode" label="字段" width="96">
                <template slot-scope="scope">
                  {{ scope.row.fieldCode | fieldCodeFilter }}
                </template>
              </el-table-column>
              <el-table-column prop="operator" label="操作符" width="120">
                <template slot-scope="scope">
                  {{ scope.row.operator | operatorFilter }}
                </template>
              </el-table-column>
              <el-table-column prop="label" label="说明"> </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <yh-popover
                    popoverTitle="修改"
                    @savePopUp="editList(scope.row, scope.$index)"
                  ></yh-popover>
                  <yh-popover
                    popoverOnly="1"
                    popoverTitle="删除"
                    @savePopUp="delList(scope.row)"
                  >
                    确定删除该信息吗？
                  </yh-popover>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <template #footer>
        <el-button size="mini" type="primary" v-if="prepare" @click="submit()">
          保 存
        </el-button>
        <el-button size="mini" type="primary" v-else> 保 存 </el-button>
      </template>
    </yh-dialog>

    <yh-dialog :dialogObj="dialogListObj">
      <yh-form
        ref="formName"
        labelWidth="110px"
        :formHead="formListHead"
        :formData="formListData"
      ></yh-form>
      <template #footer>
        <el-button size="mini" type="primary" @click="submitList()">
          保 存
        </el-button>
      </template>
    </yh-dialog>
  </div>
</template>
<script>
let that;
export default {
  filters: {
    typeFilter: function (value) {
      switch (value) {
        case "String":
          return "字符串";
        case "Integer":
          return "整形";
        case "Decimal":
          return "浮点型";
        case "Date":
          return "日期";
        case "Datetime":
          return "时间";
        case "Enum":
          return "枚举";
        case "Array":
          return "数组";
        case "Reference":
          return "档案";
        default:
          return "";
      }
    },
    operatorFilter: function (value) {
      switch (value) {
        case "Equals":
          return "等于";
        case "NotEquals":
          return "不等于";
        case "Like":
          return "包含";
        case "Greater":
          return "大于";
        case "GreaterOrEquals":
          return "大于等于";
        case "Less":
          return "小于";
        case "LessOrEquals":
          return "小于等于";
        case "In":
          return "在...之间";
        case "NotIn":
          return "在...之间（包含）";
        default:
          return "";
      }
    },
    fieldCodeFilter: function (value) {
      for (let i = 0; i < that.formData.fieldMetas.length; i++) {
        if (that.formData.fieldMetas[i].fieldCode == value) {
          return that.formData.fieldMetas[i].fieldName;
        }
      }
    },
  },
  data() {
    return {
      products: "",
      productsList: [],
      search: "",
      // 表格
      tableLoading: false,
      tableHeight: "100px",
      tableHead: [
        {
          prop: "typeCode",
          label: "档案编码",
          fixed: false,
        },
        {
          prop: "docName",
          label: "档案名称",
          fixed: false,
        },
      ],
      tableData: [],

      dialogObj: {
        dialogTitle: "新增",
        dialogOpen: false,
      },
      formRule: {
        typeCode: [
          { required: true, message: "请输入档案编码", trigger: "blur" },
        ],
        docName: [
          { required: true, message: "请输入档案名称", trigger: "blur" },
        ],
      },
      formHead: [
        {
          comp: "el-input",
          label: "档案编码",
          prop: "typeCode",
          widthSize: 2,
          bind: {
            disabled: false,
          },
        },
        {
          comp: "el-input",
          label: "档案名称",
          prop: "docName",
          widthSize: 2,
        },
      ],
      formData: {
        id: undefined,
        typeCode: undefined,
        docName: undefined,
        fieldMetas: [],
        queryFields: [],
      },

      activeName: "first",
      listRow: {},
      dialogListObj: {
        dialogTitle: "新增",
        dialogOpen: false,
      },
      formListHead: [],
      formListHeads: [
        {
          comp: "el-input",
          label: "编码",
          prop: "fieldCode",
          widthSize: 2,
        },
        {
          comp: "el-input",
          label: "名称",
          prop: "fieldName",
          widthSize: 2,
        },
        {
          comp: "el-select",
          sonComp: "el-option",
          label: "类型",
          prop: "fieldType",
          widthSize: 1,
          data: [
            {
              name: "字符串",
              id: "String",
            },
            {
              name: "整形",
              id: "Integer",
            },
            {
              name: "浮点型",
              id: "Decimal",
            },
            {
              name: "日期",
              id: "Date",
            },
            {
              name: "时间",
              id: "Datetime",
            },
            {
              name: "枚举",
              id: "Enum",
            },
            {
              name: "数组",
              id: "Array",
            },
            {
              name: "档案",
              id: "Reference",
            },
          ],
        },
        {
          comp: "el-input",
          label: "字段配置",
          prop: "fieldSettings",
          widthSize: 1,
          bind: {
            type: "textarea",
          },
        },
      ],
      formListHead1: [
        {
          comp: "el-input",
          label: "编码",
          prop: "queryCode",
          widthSize: 2,
        },
        {
          comp: "el-select",
          sonComp: "el-option",
          label: "字段",
          prop: "fieldCode",
          widthSize: 2,
          name: "fieldName",
          value: "fieldCode",
          data: [],
        },
        {
          comp: "el-select",
          sonComp: "el-option",
          label: "操作符",
          prop: "operator",
          widthSize: 1,
          data: [
            {
              name: "等于",
              id: "Equals",
            },
            {
              name: "不等于",
              id: "NotEquals",
            },
            {
              name: "包含",
              id: "Like",
            },
            {
              name: "大于",
              id: "Greater",
            },
            {
              name: "大于等于",
              id: "GreaterOrEquals",
            },
            {
              name: "小于",
              id: "Less",
            },
            {
              name: "小于等于",
              id: "LessOrEquals",
            },
            {
              name: "在...之间",
              id: "In",
            },
            {
              name: "在...之间（包含）",
              id: "NotIn",
            },
          ],
        },
        {
          comp: "el-input",
          label: "说明",
          prop: "label",
          widthSize: 1,
          bind: {
            type: "textarea",
          },
        },
      ],
      formListData: {
        fieldCode: undefined,
        fieldName: undefined,
        fieldType: undefined,
        fieldSettings: undefined,

        queryCode: undefined,
        operator: undefined,
        label: undefined,
      },
      prepare: true,
    };
  },
  created() {
    this.getHeight();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    that = this;
    this.getProduct();
  },
  methods: {
    // 表格
    getSearch() {
      this.page = 1;
      this.getData();
    },
    getData() {
      this.tableLoading = true;
      this.$axios.get(
        "docDefinitions",
        { q: this.search, productId: this.products },
        (res) => {
          this.tableLoading = false;
          var status = res.status;
          if (status.toString().substring(0, 1) == 2) {
            this.tableData = res.data;
          } else {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    // 新增
    add() {
      this.dialogObj.dialogOpen = true;
      this.dialogObj.dialogTitle = "新增";
      this.formHead[0].bind.disabled = false;
      this.formData = {
        id: undefined,
        typeCode: undefined,
        docName: undefined,
        fieldMetas: [],
        queryFields: [],
      };
    },
    edit(row) {
      this.$axios.get("docDefinitionsDetail", { id: row.typeCode }, (res) => {
        var status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.formData = res.data;
          this.formHead[0].bind.disabled = true;
          this.dialogObj.dialogOpen = true;
          this.dialogObj.dialogTitle = "修改";
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    handleClick() {},
    addList() {
      this.dialogListObj.dialogTitle = "新增";
      this.dialogListObj.dialogOpen = true;
      if (this.activeName == "first") {
        this.formListHead = this.formListHeads;
        this.formListData = {
          fieldCode: undefined,
          fieldName: undefined,
          fieldType: undefined,
          fieldSettings: undefined,
        };
      } else if (this.activeName == "second") {
        this.formListHead = this.formListHead1;
        this.formListHead[1].data = this.formData.fieldMetas;
        this.formListData = {
          queryCode: undefined,
          fieldCode: undefined,
          operator: undefined,
          label: undefined,
        };
      }
    },
    editList(row, index) {
      this.listRow = index;
      this.dialogListObj.dialogTitle = "修改";
      this.dialogListObj.dialogOpen = true;
      if (this.activeName == "first") {
        this.formListHead = this.formListHeads;
        this.formListData.fieldCode = row.fieldCode;
        this.formListData.fieldName = row.fieldName;
        this.formListData.fieldType = row.fieldType;
        this.formListData.fieldSettings = row.fieldSettings;
      } else if (this.activeName == "second") {
        this.formListHead = this.formListHead1;
        this.formListHead[1].data = this.formData.fieldMetas;
        this.formListData.queryCode = row.queryCode;
        this.formListData.fieldCode = row.fieldCode;
        this.formListData.operator = row.operator;
        this.formListData.label = row.label;
      }
    },
    delList(index) {
      if (this.activeName == "first") {
        this.formData.fieldMetas.splice(index, 1);
      } else if (this.activeName == "second") {
        this.formData.queryFields.splice(index, 1);
      }
    },
    submitList() {
      this.dialogListObj.dialogOpen = false;
      if (this.dialogListObj.dialogTitle == "新增") {
        if (this.activeName == "first") {
          this.formData.fieldMetas.push({
            fieldCode: this.formListData.fieldCode,
            fieldName: this.formListData.fieldName,
            fieldType: this.formListData.fieldType,
            fieldSettings: this.formListData.fieldSettings,
          });
        } else if (this.activeName == "second") {
          this.formData.queryFields.push({
            queryCode: this.formListData.queryCode,
            fieldCode: this.formListData.fieldCode,
            operator: this.formListData.operator,
            label: this.formListData.label,
          });
        }
      } else {
        if (this.activeName == "first") {
          this.formData.fieldMetas.splice(this.listRow, 1, this.formListData);
        } else if (this.activeName == "second") {
          this.formData.queryFields.splice(this.listRow, 1, this.formListData);
        }
      }
    },
    submit() {
      this.$refs.formName.validate((valid) => {
        if (valid) {
          this.prepare = false;
          if (this.dialogObj.dialogTitle == "修改") {
            this.$axios.jsonPost(
              "docDefinitionsEdit",
              {
                docName: this.formData.docName,
                fieldMetas: this.formData.fieldMetas,
                queryFields: this.formData.queryFields,
                productId: this.products,
              },
              (res) => {
                this.prepare = true;
                var status = res.status;
                if (status.toString().substring(0, 1) == 2) {
                  this.dialogObj.dialogOpen = false;
                  this.$message.success("修改成功");
                  this.resetForm();
                  this.getData();
                } else {
                  this.$message.error(res.data.message);
                }
              }
            );
          } else {
            this.$axios.jsonPost(
              "docDefinitions",
              {
                typeCode: this.formData.typeCode,
                docName: this.formData.docName,
                fieldMetas: this.formData.fieldMetas,
                queryFields: this.formData.queryFields,
                productId: this.products,
              },
              (res) => {
                this.prepare = true;
                var status = res.status;
                if (status.toString().substring(0, 1) == 2) {
                  this.dialogObj.dialogOpen = false;
                  this.$message.success("添加成功");
                  this.resetForm();
                  this.getData();
                } else {
                  this.$message.error(res.data.message);
                }
              }
            );
          }
        } else {
          this.$message.error("请填写必填项");
        }
      });
    },
    resetForm() {
      if (this.$refs.formName) {
        this.$refs.formName.resetFields();
      }
    },
    del(row) {
      this.$axios.jsonPost("docDefinitionsDel", { id: row.typeCode }, (res) => {
        var status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.$message.success("删除成功");
          this.getData();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getProduct() {
      this.$axios.get("products", {}, (res) => {
        var status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.productsList = res.data;
          if (this.productsList.length > 0) {
            this.products = this.productsList[0].id;
            this.getData();
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getHeight() {
      this.tableHeight = window.innerHeight - (50 + 40 + 60 + 30) + "px";
    },
  },
};
</script>
<style lang="scss">
.tab {
  .el-tabs__header {
    margin: 0;
    border-bottom: 0;
    .el-tabs__item {
      border-bottom: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.tab {
  position: relative;
  .addIcons {
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 30;
  }
  .el-tabs__header {
    padding: 0;
  }
}
</style>
